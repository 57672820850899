import React, { useState } from "react";
import "./Login.css";
import AuthUser from "./AuthUser";
import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";

function Login() {
  const backgroundStyle = {
    backgroundImage: 'url("/bgimg.jpg")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white", // Optional: Change text color to white for better visibility
  };

  const { http, setToken } = AuthUser();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const submitForm = () => {
  
    http
      .post("/login", { email, password })
      .then((res) => {
        setToken(res.data.user.name, res.data.authorization.token);
      })
      .catch((error) => { 
        toast.error("Invalid Credentials");
      });
  };

  return (
    <div style={backgroundStyle}>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 3000,
          style: {
            background: "white",
            color: "green",
          },
        }}
      />
      <div className="custom-login-form">
        <form>
          <div className="form-group">
            <label htmlFor="email">Email</label>{" "} 
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              id="email"
              name="email"
              placeholder="email"
              autoComplete="email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>{" "} 
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              name="password"
              placeholder="password"
              autoComplete="current-password"
            />
          </div>
          <button
            type="button"
            onClick={submitForm}
            className="btn btn-primary"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;

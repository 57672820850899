import React, { useEffect, useState } from "react";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NcoBnfPE({
  selectedDate,
  mergedOptions,
  allNcoPrice,
  bnfExpiryDay,
  ncoMainValue
}) {
  const [year, month, day] = selectedDate.split("-").map(Number);

  let maxDate = new Date(bnfExpiryDay); // Assuming bnfExpiryDay is in the format 'yyyy-mm-dd'
  maxDate.setDate(maxDate.getDate() + 8);

  const maxSelectDate = `${maxDate.getFullYear()}-${(maxDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${maxDate.getDate().toString().padStart(2, "0")}`;

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedNco, setSelectedNco] = useState("nco0"); // Set initial selected NCO Day option
  const [formData, setFormData] = useState({
    ncocedate: "",
    ncoceprice: "nco0_0",
  });

  useEffect(() => {
    searchTableData();
  }, [bnfExpiryDay]);
  const searchTableData = () => {
    if (!bnfExpiryDay) return;
    setLoading(true);
    const data = {
      bnfExpiryDay: bnfExpiryDay,
    };
    axios
      .post("/nco_bnf_pe_table", {
        data: data,
      })
      .then((response) => {
        setTableData(response.data);
        setLoading(false);
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        toast.error("Wrog Data");
      });

    setFormData({
      ncocedate: "",
      ncoceprice: "",
    });
  };

  const handleNcoChange = (e) => {
    setSelectedNco(e.target.value);
    selectFirstOption();
  };
  const selectFirstOption = () => {
    if (mergedOptions[selectedNco]) {
      const firstOptionKey = Object.keys(mergedOptions[selectedNco].options)[0];

      setFormData((prevData) => ({
        ...prevData,
        ncoceprice: firstOptionKey,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const NcoBNFCESubmit = (e) => {
    e.preventDefault();
    if (!bnfExpiryDay) return;
    setLoading(true);
    const { ncocedate, ncoceprice } = formData;
    const data = {
      ncocedate: ncocedate,
      ncoceprice: ncoceprice,
      selectedNco: selectedNco,
      bnfExpiryDay: bnfExpiryDay,
    };
    axios
      .post("/nco_bnf_pe", {
        data: data,
      })
      .then((response) => {
        setLoading(false);
        searchTableData();
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        toast.error("Wrong Data");
      });

    setFormData({
      ncocedate: "",
      ncoceprice: "",
    });
  };
  return (
    <div className="container-fluid border border-info rounded pt-2 pb-2">
    <h3>NCO BNF PE</h3>
    <ToastContainer
        position="top-left"
        autoClose={2000}
        closeOnClick={true}
      />
     <div className="row">
    <form onSubmit={NcoBNFCESubmit}>
      <div className="row ">
        <div className="col">
          <label htmlFor="resulttype" >NCO Day:</label>
          <select
            className="form-control"
            name="resulttype"
            value={selectedNco}
            onChange={handleNcoChange}
            style={{ minWidth: "100px" }}
            required
          >
            <option value="nco0">Nco Day</option>
            <option value="nco1">NCO + 1</option>
            <option value="nco2">NCO + 2</option>
            <option value="nco3">NCO + 3</option>
            <option value="nco4">NCO + 4</option>
          </select>
        </div>
        <div className="col">
          <label htmlFor="ncocedate">Select Date:</label>
          <input
            className="form-control"
            type="date"
            name="ncocedate"
            style={{ minWidth: "100px" }}
            value={formData.ncocedate}
            onChange={handleInputChange}
            min={bnfExpiryDay}
            max={maxSelectDate}
            required
          />
        </div>

        <div className="col">
          <label htmlFor="ncoceprice">Price Compare:</label>
          <select
            className="form-control"
            name="ncoceprice"
            style={{ minWidth: "100px" }}
            value={formData.ncoceprice}
            onChange={handleInputChange}
            required
          >
            <option value="">Select..</option>
            {mergedOptions[selectedNco] && (
              <optgroup
                key={selectedNco}
                label={mergedOptions[selectedNco].heading}
              >
                {Object.keys(mergedOptions[selectedNco].options).map(
                  (key) => (
                    <option key={key} value={`${selectedNco}_${key}`}>
                      {mergedOptions[selectedNco].options[key]}
                    </option>
                  )
                )}
              </optgroup>
            )}
          </select>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <button
            type="submit"
            className="btn btn-success"
            style={{ minWidth: 60, maxWidth: 100 }}
            disabled={
              formData.ncoceprice === "0" ||
              formData.ncoceprice === "" ||
              bnfExpiryDay === ""
            }
          >
            Save
          </button>
        )}
      </div>
    </form>
    </div>
    <div className="row pt-3">
      <div className="container-fluid border border-round ">
        <table
          className="table table-striped table-bordered border border-round  border-secondary"
        >
          <thead className="thead-dark">
            <tr>
              <th style={{ minWidth:'20%' }}>NCO</th>
              <th style={{ minWidth:'20%' }}>BNF PE Date</th>
              <th style={{ minWidth:'20%' }}>BNF PE Price</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={index}>
                <td>
                {
                    ncoMainValue[item.bnfce]
                  }
                </td>
                <td>{item.bnfdate}</td>
                <td>
                {
                    allNcoPrice[item.price]
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default NcoBnfPE
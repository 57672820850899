import React, { useEffect, useState } from "react";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";
import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";

function ChartImg({ selectedDate }) {
  const [loading, setLoading] = useState(true);
  const [imageData, setImageData] = useState({
    raw1col1Img: "",
    raw1col2Img: "",
    raw1col3Img: "",
    raw1col4Img: "",
    raw1col5Img: "",
    raw1col6Img: "",
    raw2col1Img: "",
    raw2col2Img: "",
    raw2col3Img: "",
    raw2col4Img: "",
    raw2col5Img: "",
    raw2col6Img: "",
    raw3col1Img: "",
    raw3col2Img: "",
    raw3col3Img: "",
    raw3col4Img: "",
    raw3col5Img: "",
    raw3col6Img: "",
    raw4col1Img: "",
    raw4col2Img: "",
  });
  const [selectedValues, setSelectedValues] = useState({
    raw2col1Img_select: "",
    raw2col2Img_select: "",
    raw2col3Img_select: "",
    raw2col4Img_select: "",
    raw2col5Img_select: "",
    raw2col6Img_select: "",
    raw3col5Img_select: "",
    raw3col6Img_select: "",
    raw4col1Img_int_1: "00.00",
    raw4col1Img_int_2: "00.00",
    raw4col2Img_int_1: "00.00",
    raw4col2Img_int_2: "00.00",
    raw4col2Img_int_3: "00.00",
    raw4col2Img_int_4: "00.00",
  });
  const chartname = [
    {
      name: "raw1col1Img",
      header: "Nifty Ce & Pe Contract",
      type: { selectOption: "NO" },
    },
    {
      name: "raw1col2Img",
      header: "BNF Ce & Pe Contract",
      type: { selectOption: "NO" },
    },
    {
      name: "raw1col3Img",
      header: "Nifty Spot",
      type: { selectOption: "NO" },
    },
    {
      name: "raw1col4Img",
      header: "BNF Spot ",
      type: { selectOption: "NO" },
    },
    {
      name: "raw1col5Img",
      header: "Nifty Heavy Weight overview",
      type: { selectOption: "NO" },
    },
    {
      name: "raw1col6Img",
      header: "BNF Heavy Weight overview",
      type: { selectOption: "NO" },
    },
    {
      name: "raw2col1Img",
      header: "Nifty  Positional Trending OI",
      type: { selectOption: "YES" },
    },
    {
      name: "raw2col2Img",
      header: " Bank Nifty  Positional Trending OI",
      type: { selectOption: "YES" },
    },
    {
      name: "raw2col3Img",
      header: "Nifty Trending OI",
      type: { selectOption: "YES" },
    },
    {
      name: "raw2col4Img",
      header: "Bank Nifty Trending OI",
      type: { selectOption: "YES" },
    },
    {
      name: "raw2col5Img",
      header: "Nifty Cumilative OI",
      type: { selectOption: "YES" },
    },
    {
      name: "raw2col6Img",
      header: "BNF Cumilative OI",
      type: { selectOption: "YES" },
    },
    {
      name: "raw3col1Img",
      header: "Nifty Option Chain",
      type: { selectOption: "NO" },
    },
    {
      name: "raw3col2Img",
      header: "BNF Option Chain",
      type: { selectOption: "NO" },
    },
    {
      name: "raw3col3Img",
      header: "Nifty Future OI Analysis",
      type: { selectOption: "NO" },
    },
    {
      name: "raw3col4Img",
      header: "BNF Future OI Analysis",
      type: { selectOption: "NO" },
    },
    {
      name: "raw3col5Img",
      header: "Indian Market Over View",
      type: { selectOption: "YES" },
    },
    {
      name: "raw3col6Img",
      header: "Global Market Overview ",
      type: { selectOption: "YES" },
    },
    {
      name: "raw4col1Img",
      header: "FII & DII Capital Market Activities",
      type: { intValue: "YES", selectOption: "NO" },
    },
    {
      name: "raw4col2Img",
      header: "FII Derivative Activities",
      type: { intValue4: "YES", selectOption: "NO" },
    },
  ];

  const handleImageUpload = (event, cardName) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png"]; // Allowed image types
    const maxSize = 1024 * 1024; // 1MB in bytes
    if (file && allowedTypes.includes(file.type) && file.size <= maxSize) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData((prevImageData) => ({
          ...prevImageData,
          [cardName]: reader.result,
        }));
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      toast.error("Image should .png below 1mb");
    }
  };

  const handleSelectChange = (event, cardName) => {
    const selectedValue = event.target.value;
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [cardName]: selectedValue,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("/upload", {
        data: imageData,
        date: selectedDate,
        selectedValues: selectedValues,
      });
      if (response.data.status === "YES" || response.data.status === "NO") {
        toast.success(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error saving images:", error);
      toast.error("Failed to save images.");
    }
  };

  // Load image data when component mounts
  useEffect(() => {
    setLoading(true);
    const fetchImageData = async () => {
      try {
        const response = await axios.post("/getupload", { date: selectedDate });
 
        if (response.data.status === "YES" || "NO") {
          setImageData(response.data.images);
          setSelectedValues(response.data.items);
        }
        if (response.data.status === "NO") {
        toast.loading('No Data Please Upload...');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching images:", error);
        toast.error("Server Error");
      }
    };

    fetchImageData();
  }, [selectedDate]);

  return (
    <div className="container-fluid border rounded pt-3">
      <h3>Chart</h3>
      {/* <ToastContainer /> */}
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 3000,
          style: {
            background: "white",
            color: "green",
          },
        }}
      />
      <form className="border-bottom border-danger p-3" onSubmit={handleSubmit}>
        <div className="row">
          {chartname.map((entry, index) => (
            <div key={index} className="col-xs-6 col-sm-4 col-lg-2 d-flex">
              <div className="card flex-fill border-0 illustration">
                <div
                  className="card-body p-0 d-flex flex-fill"
                  style={{ minWidth: "150px" }}
                >
                  <div className="row g-0 w-100 card_color">
                    <div dangerouslySetInnerHTML={{ __html: entry.header }} />
                    {loading ? (
                      <Spinner />
                    ) : (
                      <a
                        href={imageData[entry.name] || ""}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="card-header"
                          style={{
                            height: "100px",
                            backgroundImage: `url(${
                              imageData[entry.name] || ""
                            })`,
                            backgroundSize: "cover",
                            cursor: "pointer", // Add cursor pointer to indicate it's clickable
                          }}
                        />
                      </a>
                    )}

                    {loading ? (
                      <Spinner />
                    ) : (
                      <div className="card-body text-center">
                        <div className="row">
                          <div className="col-6">
                            <input
                              className="form-control"
                              type="file"
                              onChange={(event) =>
                                handleImageUpload(event, entry.name)
                              }
                              //  required
                            />
                          </div>
                          <div className="col-6">
                            <p>upload</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            {entry.type &&
                              entry.type.selectOption === "YES" && (
                                <select
                                  className="form-select"
                                  onChange={(event) =>
                                    handleSelectChange(
                                      event,
                                      `${entry.name}_select`
                                    )
                                  }
                                  name={`${entry.name}_select`}
                                  value={selectedValues[`${entry.name}_select`]}
                                  // required
                                >
                                  <option value=""></option>
                                  <option value="1">Nutral</option>
                                  <option value="2">Highly bullish</option>
                                  <option value="3">Bullish</option>
                                  <option value="4">Bearish</option>
                                  <option value="5">Highly Bearish</option>
                                </select>
                              )}
                            {entry.type && entry.type.intValue === "YES" && (
                              <>
                                <div className="row">
                                  <span>FII Net Value</span>
                                  <input
                                    name={`${entry.name}_int_1`}
                                    className="form-control"
                                    type="number"
                                    placeholder="FII Net Value..."
                                    value={
                                      selectedValues[`${entry.name}_int_1`]
                                    }
                                    onChange={(event) =>
                                      handleSelectChange(
                                        event,
                                        `${entry.name}_int_1`
                                      )
                                    }
                                    // required
                                  />
                                </div>
                                <div className="row">
                                  <span>DII Net Value</span>
                                  <input
                                    name={`${entry.name}_int_2`}
                                    className="form-control"
                                    type="number"
                                    placeholder="DII Net Value..."
                                    value={
                                      selectedValues[`${entry.name}_int_2`]
                                    }
                                    onChange={(event) =>
                                      handleSelectChange(
                                        event,
                                        `${entry.name}_int_2`
                                      )
                                    }
                                    // required
                                  />
                                </div>
                              </>
                            )}
                            {entry.type && entry.type.intValue4 === "YES" && (
                              <>
                                <div className="row">
                                  <span>Index Futures</span>
                                  <input
                                    name={`${entry.name}_int_1`}
                                    className="form-control"
                                    type="number"
                                    placeholder="Index Futures..."
                                    value={
                                      selectedValues[`${entry.name}_int_1`]
                                    }
                                    onChange={(event) =>
                                      handleSelectChange(
                                        event,
                                        `${entry.name}_int_1`
                                      )
                                    }
                                    // required
                                  />
                                </div>
                                <div className="row">
                                  <span>Index Options</span>
                                  <input
                                    name={`${entry.name}_int_2`}
                                    className="form-control"
                                    type="number"
                                    placeholder="Index Options..."
                                    value={
                                      selectedValues[`${entry.name}_int_2`]
                                    }
                                    onChange={(event) =>
                                      handleSelectChange(
                                        event,
                                        `${entry.name}_int_2`
                                      )
                                    }
                                    // required
                                  />
                                </div>
                                <div className="row">
                                  <span>Stock Futures</span>
                                  <input
                                    name={`${entry.name}_int_3`}
                                    className="form-control"
                                    type="number"
                                    placeholder="Stock Futures..."
                                    value={
                                      selectedValues[`${entry.name}_int_3`]
                                    }
                                    onChange={(event) =>
                                      handleSelectChange(
                                        event,
                                        `${entry.name}_int_3`
                                      )
                                    }
                                    // required
                                  />
                                </div>
                                <div className="row">
                                  <span>Stock Options</span>
                                  <input
                                    name={`${entry.name}_int_4`}
                                    className="form-control"
                                    type="number"
                                    placeholder="Stock Options..."
                                    value={
                                      selectedValues[`${entry.name}_int_4`]
                                    }
                                    onChange={(event) =>
                                      handleSelectChange(
                                        event,
                                        `${entry.name}_int_4`
                                      )
                                    }
                                    // required
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <button
            type="submit"
            className="btn btn-success card_color blink"
            data-toggle="button"
            aria-pressed="false"
          >
            Submit
          </button>
        )}
      </form>
    </div>
  );
}

export default ChartImg;

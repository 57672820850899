import React, { useState, useEffect } from "react";
import IndianMarketADRcard from "./IndianMarketADRcard";
import axios from "./axios";
import Spinner from "../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import IndianMarketADRstockModal from "./IndianMarketADRstockModal";

function IndianMarketADR({ selectedDate }) {
  const [loading, setLoading] = useState(false);
  const [dataExist, setDataExist] = useState("NO");
//   const [stockName, setstockName] = useState("");
//   const [stockValue, setstockValue] = useState("");
  const [ADRdata,setADRdata] =useState([]);
  const [triggerEffect, setTriggerEffect] = useState(false);
 

//   const [data, setData] = useState([]);
 

  useEffect(() => {
    readADRdatas();
  }, [selectedDate , triggerEffect]);
 
   const readADRdatas =()=>{
    setLoading(true);
    axios.post('read-adr', {date:selectedDate})
  .then(response => { 
    // console.log(response.data.items)
    if(response.data.items !== ""){
    setADRdata(response.data.items)
  }
    if(response.data.status === 'YES'){
      setDataExist('YES')
      }else{
        setDataExist('NO')
      }
      setLoading(false); 
  })
  .catch(error => {
    // Handle error
    console.error('Error:', error);
  });
   }
  const handleStockChange = (e, index) => { 
    const { value } = e.target;
    // console.log('Selected stock:', ADRdata[index].stocks, 'New value:', value);
    setADRdata(prevData => {
      const newData = [...prevData]; // Create a copy of the previous state array
      newData[index].adr_value = value; // Update the adr_value of the specified index
      return newData; // Return the updated array
    });
  };

 
  const marketADRsubmit = (event) => { 
    event.preventDefault(); 
    const postData = {
      selectedDate: selectedDate,
      ADRdata: ADRdata
    }; 
    setLoading(true)
    
    const apiUrl = dataExist !== 'NO' ? `/adr-edit/${selectedDate}` : '/adr-create';
    axios({
      method: dataExist !== 'NO'  ? 'put' : 'post',
      url: apiUrl,
      data: postData
    })
    .then(response => { 
       
      if(response.data.status === 'YES'){
        setDataExist('YES')
        }else{
          setDataExist('NO')
        }
      setLoading(false)
      toast.success(response.data.message);
    //   console.log(response.data); 
    })
    .catch(error => {
      console.error("Error sending data:", error);
      toast.error('Wrong Data Entered');
      setLoading(false)
    });
  }
  
  
 
  return (
    <div className="container-fluid border rounded pt-3">
      <ToastContainer
          position="top-left"
          autoClose={2000}
          closeOnClick={true}
        />
      <h3>Indian Market ADR</h3>
      
      <div className="row">
        <IndianMarketADRstockModal onStockAdded={() => setTriggerEffect(prev => !prev)} />
      </div>
      
      <form onSubmit={marketADRsubmit} className="border-bottom border-danger p-3">
        <div className="row">
        {ADRdata.map((datas, index) => (
        <div key={index} className="col-xs-6 col-sm-4 col-lg-2 d-flex">
           <IndianMarketADRcard 
            datas={datas}
            handleStockChange={(e) => handleStockChange(e, index)}
           />
          
        </div>
      ))} 
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <button
            type="submit"
            className="btn btn-success card_color blink"
            // data-toggle="button"
            aria-pressed="false"
          >
            Submit
          </button>
        )}
        
      </form>
    </div>
  );
}

export default IndianMarketADR;

import React,{useState,useEffect} from 'react'
import AfterMainGlobalMarketCard from './AfterMainGlobalMarketCard'
import axios from './axios';
import Spinner from '../components/Spinner'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AfterMainGlobalMarket({selectedDate}) {
  const [loading,setLoading] = useState(true);
  const [dataExist,setDataExist] = useState('NO');
    const [selectGlobalMarket,setSelectGlobalMarket] = useState({ 
        Fraw1col1btnradio1: "0.00",
        Fraw1col2btnradio2: "0.00",
        Fraw1col3btnradio3: "0.00",
        Fraw1col4btnradio4: "0.00",
        Fraw1col5btnradio5: "0.00",
        Fraw1col6btnradio6: "0.00",
        Fraw2col1btnradio1: "0.00",
        Fraw2col2btnradio2: "0.00",
        Fraw2col3btnradio3: "0.00",
        Fraw2col3btnradio3: "0.00",
    });
    useEffect(() => {
      setLoading(true)
      axios
      .post("/AfetglobalMarket-check-date", {
        date: selectedDate,
      })
      .then((response) => {
        if(response.data.status === 'YES'){ 
        setDataExist('YES')
        }else{ 
          setDataExist('NO')
        }
        setSelectGlobalMarket(response.data.items); 
        setLoading(false)
        // console.log(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    }, [selectedDate])
    const handleGlobalMarket = (e) => { 
      const { name,value,type,checked} = e.target;
      setSelectGlobalMarket((prevData)=>({
        ...prevData,
        [name]: value
      })
      )
    };

    const globalMarketSubmit = (event) => {
      event.preventDefault();
      const data = {
        ...selectGlobalMarket,
        dateFind: selectedDate
      };
      let dateRaw = dataExist; 
      setLoading(true)
      const apiUrl = dateRaw !== 'NO' ? `/AfetglobalMarket-Edit/${selectedDate}` : '/AfetglobalMarket-create';
      axios({
        method: dateRaw !== 'NO'  ? 'put' : 'post',
        url: apiUrl,
        data: data
      })
      .then(response => {           
        if(response.data.status === 'YES'){
          setDataExist('YES')
          }else{
            setDataExist('NO')
          }
        setLoading(false)
        toast.success(response.data.message);
        // console.log(response.data.message); 
      })
      .catch(error => {
        console.error("Error sending data:", error);
        toast.error('Wrong Data Entered');
        setLoading(false)
      });
    }
    
  const marketglobal = [
    {
      name: "Fraw1col1btnradio1",
      header: 'DXY <span style="color: red">(+/-)</span>',
      type: {radio : 'YES' , selectOption : 'NO' } 
    },
    {
      name: "Fraw1col2btnradio2",
      header: 'US30  <span style="color: red">(+/-)</span>',
      type: {radio : 'YES' , selectOption : 'NO'} 
    },
    {
      name: "Fraw1col3btnradio3",
      header:
        'US100 <span style="color: red">(+/-)</span>',       
    },
    {
      name: "Fraw1col4btnradio4",
      header:
        'S&P  <span style="color: red">(+/-)</span>',       
    },
    {
      name: "Fraw1col5btnradio5",
      header: 'VIX <span style="color: red">(+/-)</span>',     
    }, {
      name: "Fraw1col6btnradio6",
      header: 'FTSE <span style="color: red">(+/-)</span>',     
    }, 
    {
      name: "Fraw2col1btnradio1",
      header: 'GER40  <span style="color: red">(+/-)</span>',     
    },
    {
      name: "Fraw2col2btnradio2",
      header: 'XAUUSD  <span style="color: red">(+/-)</span>',     
    },
    {
      name: "Fraw2col3btnradio3",
      header: 'NIKKEI <span style="color: red">(+/-)</span>',     
    },
    {
      name: "Fraw2col4btnradio4",
      header: 'USDINR <span style="color: red">(+/-)</span>',     
    },
   
  ];
  return (
    <div className="container-fluid border rounded pt-3">
      <ToastContainer
          position="top-left"
          autoClose={2000}
          closeOnClick={true}
        />
    <h3>After Main Global Market</h3>
   
    <form onSubmit={globalMarketSubmit}   className="border-bottom border-danger p-3">
      <div className="row">
        {marketglobal.map((entry, index) => (
          <div key={index} className="col-xs-6 col-sm-4 col-lg-2 d-flex">
            <AfterMainGlobalMarketCard
              cardContent={{
                header: entry.header,
                name : entry.name
              }}
              handleGlobalMarket={handleGlobalMarket}
              selectGlobalMarket = {selectGlobalMarket}
              typeReq = {  entry.type}
              loading = {loading}
            />
          </div>
        ))}
      </div>
      {
          loading ? <Spinner /> :
      <button
        type="submit"
        className="btn btn-success card_color blink"
        // data-toggle="button"
        aria-pressed="false"
      >
        Submit
      </button>
    }  
     
    </form>
    
  </div>
  )
}

export default AfterMainGlobalMarket
import React, { useState, useEffect } from "react";
import MajorNewsCard from "./MajorNewsCard";
import axios from "./axios";
import Spinner from "../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function MajorNews({ selectedDate }) {
  const [loading, setLoading] = useState(true);
  const [dataExist, setDataExist] = useState("NO");
  const [selectMajorNews, setSelectMajorNews] = useState({
    Graw1col1btnradio1: "NO",
    Graw1col2btnradio2: "NO",
    Graw1col3btnradio3: "NO",
    Graw1col4btnradio4: "NO",
    Graw1col5btnradio5: "NO",
  });

  useEffect(() => {
    setLoading(true);
    axios
      .post("/majorNews-check-date", {
        date: selectedDate,
      })
      .then((response) => {
        if (response.data.status === "YES") {
          setDataExist("YES");
        } else {
          setDataExist("NO");
        }
        setSelectMajorNews(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [selectedDate]);

  const handleMajorNews = (e) => {
    const { name, value } = e.target;
    setSelectMajorNews((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const MajorNewsSubmit = (event) => {
    event.preventDefault();
    // console.log(selectMajorNews)
    const data = {
      ...selectMajorNews,
      dateFind: selectedDate,
    };
    let dateRaw = dataExist;
    setLoading(true);
    const apiUrl =
      dateRaw !== "NO"
        ? `/majorNews-Edit/${selectedDate}`
        : "/majorNews-create";
    axios({
      method: dateRaw !== "NO" ? "put" : "post",
      url: apiUrl,
      data: data,
    })
      .then((response) => {
        if (response.data.status === "YES") {
          setDataExist("YES");
        } else {
          setDataExist("NO");
        }
        setLoading(false);
        toast.success(response.data.message);
        //   console.log(response.data);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        toast.error("Wrong Data Entered");
        setLoading(false);
      });
  };

  const majornews = [
    {
      name: "Graw1col1btnradio1",
      header: "Any Thret Related to India",
    },
    {
      name: "Graw1col2btnradio2",
      header: "Any WHO negative News",
    },
    {
      name: "Graw1col3btnradio3",
      header: "Any Natural calamities News Inside India",
    },
    {
      name: "Graw1col4btnradio4",
      header: "Any Natural calamities News Inside India",
    },
    {
      name: "Graw1col5btnradio5",
      header: "Any World War News",
    },
  ];
  return (
    <div className="container-fluid border rounded pt-3">
      <ToastContainer
          position="top-left"
          autoClose={2000}
          closeOnClick={true}
        />
      <h3>Major News</h3>

      <form
        onSubmit={MajorNewsSubmit}
        className="border-bottom border-danger p-3"
      >
        <div className="row">
          {majornews.map((entry, index) => (
            <div key={index} className="col-xs-6 col-sm-6 d-flex">
              <MajorNewsCard
                cardContent={{
                  header: entry.header,
                  name: entry.name,
                }}
                handleMajorNews={handleMajorNews}
                selectMajorNews={selectMajorNews[entry.name]}
                //   selectedNiftyOption2 = {selectedNiftyOption}
                loading={loading}
              />
            </div>
          ))}
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <button
            type="submit"
            className="btn btn-success card_color blink"
            // data-toggle="button"
            aria-pressed="false"
          >
            Submit
          </button>
        )}
       
      </form>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/Modal";
import CloseIcon from "@mui/icons-material/Cancel";
import NiftyHeavyWeightageStockResultCalenderStoke from "./NiftyHeavyWeightageStockResultCalenderStoke";

function NiftyHeavyWeightageStockResultCalender() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  const selectNiftyDate = formattedDate;
  const [loading, setLoading] = useState(true);
  const [rowsTable, setrowsTable] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [NiftyCalederOption, setNiftyCalederOption] = useState({
    stockName: "1",
    resulttype: "Quarterly",
    expecteddate: selectNiftyDate,
    resultvalue: "NO",
    datetime: formattedDateTime,
  });

  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const response = await axios.get("/nifty-heavy-select-stocks");
        if (Array.isArray(response.data)) {
          // Check if response data is an array
          setStocks(response.data);
        } else {
          console.error("Stocks data is not an array:", response.data);
        }
      } catch (error) {
        console.error("Error fetching stocks:", error);
      }
    };
    fetchStocks();
  }, [triggerEffect]);
  const [NiftyHeavyToEdit, setNiftyHeavyToEdit] = useState(null);

  useEffect(() => {
    tableDatFetch();
  }, []);
  const tableDatFetch = () => {
    setLoading(true);
    axios
      .get("/nifty-heavy-calender-table")
      .then((response) => {
        setrowsTable(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleNiftyHeavyChange = (event) => {
    const { name, value } = event.target;

    setNiftyCalederOption((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleEdittChange = (event) => {
    const { name, value } = event.target;

    setNiftyHeavyToEdit((prevData) => ({
      ...prevData,
      [name]: value,
    })); 
  };

  const NiftyHeavyFormSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const apiUrl = "/nifty-heavy-calender-create";
    axios({
      method: "post",
      url: apiUrl,
      data: NiftyCalederOption,
    })
      .then((response) => { 
        if (response.data.status === "YES") {
          tableDatFetch();
        }
        setLoading(false);
        notifySuccess(response.data.message);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        notifySuccess("Wrong Data Entered");
        setLoading(false);
      });
  };
  const editNiftyFormSubmit = (event) => {
    event.preventDefault();
    const id = NiftyHeavyToEdit["id"];
    const apiUrl = `/nifty-heavy-calender-Edit/${id}`;
    axios({
      method: "put",
      url: apiUrl,
      data: NiftyHeavyToEdit,
    })
      .then((response) => { 
        const updatedRows = rowsTable.map((row) =>
          row.id === id ? { ...row, ...NiftyHeavyToEdit } : row
        );
        setrowsTable(updatedRows);

        notifySuccess(response.data.message);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        notifySuccess("Wrong Data Entered");
        setLoading(false);
      });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 20,
      headerClassName: "custom-header",
    },
    {
      field: "stockName",
      headerName: "Stock Name",
      minWidth: 200,
      headerClassName: "custom-header",
    },
    {
      field: "stockId",
      headerName: "stockId",
      hide: true,
    },
    {
      field: "resulttype",
      headerName: "Result Type",
      minWidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "expecteddate",
      headerName: "Expected Date",
      minWidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "resultvalue",
      headerName: "Result",
      minWidth: 100,
      headerClassName: "custom-header",
    },
    {
      field: "datetime",
      headerName: "Date Time",
      minWidth: 170,
      headerClassName: "custom-header",
    },
    {
      field: "edit",
      headerName: "Edit",
      minWidth: 70,
      renderCell: (params) => (
        <button onClick={() => handleEditClick(params.row.id)}>Edit</button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      minWidth: 70,
      renderCell: (params) => (
        <button onClick={() => handleDeleteClick(params.row.id)}>Delete</button>
      ),
    },
  ];
  const notifySuccess = (data) => {
    if (typeof data === "string" && data.toLowerCase() === "error value") {
      toast.error("Wrong Data Entered");
    } else {
      toast.success(data);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setNiftyHeavyToEdit(null);
  };
  const handleEditClick = (id) => {
    const event = rowsTable.find((row) => row.id === id);
    setNiftyHeavyToEdit(event);
    setIsOpen(true);
  };
  const handleDeleteClick = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (isConfirmed) {
      setLoading(true);
      axios
        .delete(`/nifty-heavy-calender/${id}`)
        .then((response) => {
          setrowsTable(rowsTable.filter((row) => row.id !== id));
          // tableDatFetch();
          toast.success(response.data.message);
        })
        .catch((error) => {
          console.error("Error deleting event:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Deletion cancelled.");
    }
  };

  return (
    <div className="container-fluid ">
    <div className="row">
      <div className="col-8"><h3>Nifty Heavy Weightage Stock Result Calender</h3></div>
      <div className="col-4"><NiftyHeavyWeightageStockResultCalenderStoke
        onStockAdded={() => setTriggerEffect((prev) => !prev)}
      /></div>
    </div> 
      <ToastContainer />
      <form onSubmit={NiftyHeavyFormSubmit}>
        <div className="row">
          <div className="col">
            <label htmlFor="stockName">Stock Name:</label>
            <select
              className="form-control"
              name="stockName"
              style={{ minWidth: "200px" }}
              value={NiftyCalederOption["stockName"]}
              onChange={handleNiftyHeavyChange}
              required
            > 
              {Array.isArray(stocks) &&
                stocks.map(
                  (
                    stock  
                  ) => (
                    <option key={stock.id} value={stock.id}>
                      {stock.stocks}
                    </option>
                  )
                )}
            </select>
          </div>
          <div className="col">
            <label htmlFor="resulttype">Result Type:</label>
            <select
              className="form-control"
              name="resulttype"
              value={NiftyCalederOption["resulttype"]}
              style={{ minWidth: "100px" }}
              onChange={handleNiftyHeavyChange}
              required
            >
              <option value="Quarterly">Quarterly</option>
              <option value="Yearly">Yearly</option>
            </select>
          </div>
          <div className="col">
            <label htmlFor="expecteddate">Expected Date:</label>
            <input
              className="form-control"
              type="date"
              name="expecteddate"
              style={{ minWidth: "100px" }}
              value={NiftyCalederOption["expecteddate"]}
              onChange={handleNiftyHeavyChange}
              required
            />
          </div>

          <div className="col">
            <label htmlFor="resultvalue">Result Value:</label>
            <select
              className="form-control"
              name="resultvalue"
              style={{ minWidth: "100px" }}
              value={NiftyCalederOption["resultvalue"]}
              onChange={handleNiftyHeavyChange}
              required
            >
              <option value="NO">NO</option>
              <option value="Highly +ve">Highly +ve</option>
              <option value="Highly -ve">Highly -ve</option>
              <option value="Normal +ve">Normal +ve</option>
              <option value="Normal -ve">Normal -ve</option>
              <option value="No Change">No Change</option>
            </select>
          </div>
          <div className="col">
            <label htmlFor="datetime">Published date and Time:</label>
            <input
              className="form-control"
              name="datetime"
              type="datetime-local"
              style={{ minWidth: "170px" }}
              value={NiftyCalederOption["datetime"]}
              onChange={handleNiftyHeavyChange}
              required
            />
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <button
              type="submit"
              className="btn btn-success"
              style={{ minWidth: 60, maxWidth: 100 }}
            >
              Save
            </button>
          )}
        </div>
      </form>
      <br />
      <div className=" row">
        <div className="col">
          <div style={{ height: 600, backgroundColor: "lightblue" }}>
            {loading ? (
              <Spinner />
            ) : (
              <DataGrid
                rows={rowsTable}
                columns={columns.filter((column) => !column.hide)}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection
              />
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <div className="modal-header">
          <h2>Modal Title</h2>
          <span className="modal-close" onClick={handleCloseModal}>
            <CloseIcon />
          </span>
        </div>
        <div className="container-fluid">
          <br />
          <br />
          {NiftyHeavyToEdit && (
            <form onSubmit={editNiftyFormSubmit}>
              <div className="row ">
                <div className="row px-2 py-2">
                  <div className="col-6">
                    <h4 className="text-dark">Stock Name</h4>
                  </div>
                  <div className="col-6">
                    <select
                      className="form-control"
                      name="stockName"
                      onChange={handleEdittChange}
                      disabled
                    >
                      <option value={NiftyHeavyToEdit["stockId"]}>
                        {NiftyHeavyToEdit["stockName"]}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row px-2 py-2">
                  <div className="col-6">
                    <h4 className="text-dark">Result Type</h4>
                  </div>
                  <div className="col-6">
                    <select
                      className="form-control"
                      name="resulttype"
                      value={NiftyHeavyToEdit["resulttype"]}
                      onChange={handleEdittChange}
                      required
                    >
                      <option value="Quarterly">Quarterly</option>
                      <option value="Yearly">Yearly</option>
                    </select>
                  </div>
                </div>
                <div className="row px-2 py-2">
                  <div className="col-6">
                    <h4 className="text-dark">Expected Date</h4>
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      type="date"
                      name="expecteddate"
                      value={NiftyHeavyToEdit["expecteddate"]}
                      onChange={handleEdittChange}
                      required
                    />
                  </div>
                </div>
                <div className="row px-2 py-2">
                  <div className="col-6">
                    <h4 className="text-dark">Result Value</h4>
                  </div>
                  <div className="col-6">
                    <select
                      className="form-control"
                      name="resultvalue"
                      value={NiftyHeavyToEdit["resultvalue"]}
                      onChange={handleEdittChange}
                      required
                    >
                      <option value="NO">NO</option>
                      <option value="Highly +ve">Highly +ve</option>
                      <option value="Highly -ve">Highly -ve</option>
                      <option value="Normal +ve">Normal +ve</option>
                      <option value="Normal -ve">Normal -ve</option>
                      <option value="No Change">No Change</option>
                    </select>
                  </div>
                </div>
                <div className="row px-2 py-2">
                  <div className="col-6">
                    <h4 className="text-dark">Published DateTime</h4>
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      name="datetime"
                      type="datetime-local"
                      value={NiftyHeavyToEdit["datetime"]}
                      onChange={handleEdittChange}
                      required
                    />
                  </div>
                </div>

                <br />
                <br />
                <div className="row px-2 py-2">
                  <div className="col-6"></div>
                  <div className="col-6">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-success"
                        style={{ width: 60 }}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default NiftyHeavyWeightageStockResultCalender;

import React ,{useState,useEffect} from "react";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";
import NcoBnfCE from "./NcoBnfCE";
import NcoBnfPE from "./NcoBnfPE";
import NiftyCePeRoute from "./NiftyCePeRoute";
import BnfCePeRoute from "./BnfCePeRoute";


function NcopageMain({selectedDate}) {
  
 const ncoMainValue = {
  'nco0' : "Nco Day",
  'nco1' : "NCO + 1",
  'nco2' : "NCO + 2",
  'nco3' : "NCO + 3",
  'nco4' : "NCO + 4",
 }
  const NcoDayPrice = {
    0: "0-Below (Exp)",
    1: "1-Exact Low (Exp)",
    2: "2-Between (Exp)",
    3: "3-Exact High (Exp)",
    4: "4-High (Exp)",
  };
  const Nco1Price = {
    5: "5-Below (Nco)",
    6: "6-Exact Low (Nco)",
    7: "7-Between (Nco)",
    8: "8-Exact High (Nco)",
    9: "9-High (Nco)",
  };
  const Nco2Price = {
    10: "10-Below (Nco+1)",
    11: "11-Exact Low (Nco+1)",
    12: "12-Between (Nco+1)",
    13: "13-Exact High (Nco+1)",
    14: "14-High (Nco+1)",
  };
  const Nco3Price = {
    15: "15-Below (Nco+2)",
    16: "16-Exact Low (Nco+2)",
    17: "17-Between (Nco+2)",
    18: "18-Exact High (Nco+2)",
    19: "19-High (Nco+2)",
  };
  const Nco4Price = {
    20: "20-Below (Nco+3)",
    21: "21-Exact Low (Nco+3)",
    22: "22-Between (Nco+3)",
    23: "23-Exact High (Nco+3)",
    24: "24-High (Nco+3)",
  };
 
  const allNcoPrice = { ...NcoDayPrice, ...Nco1Price, ...Nco2Price, ...Nco3Price, ...Nco4Price };
 
  const mergedOptions = {
    nco0: {
      heading: "Nco Day",
      options: { ...NcoDayPrice },
    },
    nco1: {
      heading: "NCO + 1",
      options: { ...NcoDayPrice, ...Nco1Price },
    },
    nco2: {
      heading: "NCO + 2",
      options: { ...NcoDayPrice, ...Nco1Price, ...Nco2Price },
    },
    nco3: {
      heading: "NCO + 3",
      options: { ...NcoDayPrice, ...Nco1Price, ...Nco2Price, ...Nco3Price },
    },
    nco4: {
      heading: "NCO + 4",
      options: { ...NcoDayPrice, ...Nco1Price, ...Nco2Price, ...Nco3Price, ...Nco4Price },
    },
  };


  return (
    <div className="row">
      <div className="row border border-warning rounded pt-5 pb-3 mb-5">
         <NiftyCePeRoute  selectedDate={selectedDate} mergedOptions={mergedOptions} allNcoPrice={allNcoPrice} ncoMainValue={ncoMainValue}/>
      </div>
     
      <div className="row border border-danger rounded pt-5 pb-3">
         <BnfCePeRoute  selectedDate={selectedDate} mergedOptions={mergedOptions} allNcoPrice={allNcoPrice} ncoMainValue={ncoMainValue} />
      </div>
    
      
    </div>
  );
}

export default NcopageMain;

import React, { useEffect, useState } from "react";
import AfterIndianMarketCard from "./AfterIndianMarketCard";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../components/Spinner";

function AfterIndianMarket({ selectedDate, currentTime, currentDateTime }) {
  // console.log(currentTime)
  const [dataExist, setDataExist] = useState("NO");
  const [loading, setLoading] = useState(true);
  const [selectAfterIndia, stSelectAfterIndia] = useState({
    Draw1col1btnradio1: "NO",
    Draw1col1btnradio1_percent: "0",
    Draw1col1btnradio1_time: currentTime,
    Draw1col1btnradio1_B: "NO",
    Draw1col1btnradio1_B_percent: "0",
    Draw1col1btnradio1_B_time: currentTime,

    Draw1col2btnradio2: "NO",
    Draw1col2btnradio2_percent: "0",
    Draw1col2btnradio2_time: currentTime,
    Draw1col2btnradio2_B: "NO",
    Draw1col2btnradio2_B_percent: "0",
    Draw1col2btnradio2_B_time: currentTime,

    Draw1col3btnradio3: "NO",
    Draw1col3btnradio3_percent: "0",
    Draw1col3btnradio3_time: currentTime,
    Draw1col3btnradio3_B: "NO",
    Draw1col3btnradio3_B_percent: "0",
    Draw1col3btnradio3_B_time: currentTime,

    Draw2col1btnradio1: "NO",
    Draw2col1btnradio1_percent: "0",
    Draw2col1btnradio1_time: currentTime,
    Draw2col1btnradio1_B: "NO",
    Draw2col1btnradio1_B_percent: "0",
    Draw2col1btnradio1_B_time: currentTime,

    Draw2col2btnradio2: "NO",
    Draw2col2btnradio2_percent: "0",
    Draw2col2btnradio2_B: "NO",
    Draw2col2btnradio2_B_percent: "0",

    Draw3col1btnradio1: "NO",
    Draw3col1btnradio1_dateTime: currentDateTime,
    Draw3col1btnradio1_B: "NO",
    Draw3col1btnradio1_B_dateTime: currentDateTime,

    Draw3col2btnradio2: "NO",
    Draw3col2btnradio2_dateTime: currentDateTime,
    Draw3col2btnradio2_B: "NO",
    Draw3col2btnradio2_B_dateTime: currentDateTime,

    Draw3col3btnradio3: "0",
    Draw3col3btnradio3_H: "0",
    Draw3col3btnradio3_L: "0",
    Draw3col3btnradio3_C: "0",
    Draw3col3btnradio3_B: "0",
    Draw3col3btnradio3_B_H: "0",
    Draw3col3btnradio3_B_L: "0",
    Draw3col3btnradio3_B_C: "0",

    Draw4col1btnradio1: "0",
    Draw4col1btnradio1_H: "0",
    Draw4col1btnradio1_L: "0",
    Draw4col1btnradio1_C: "0",
    Draw4col1btnradio1_B: "0",
    Draw4col1btnradio1_B_H: "0",
    Draw4col1btnradio1_B_L: "0",
    Draw4col1btnradio1_B_C: "0",

    Draw4col2btnradio2: "0",
    Draw4col2btnradio2_H: "0",
    Draw4col2btnradio2_L: "0",
    Draw4col2btnradio2_C: "0",

    Draw4col3btnradio3: "0",
    Draw4col3btnradio3_PUT: "0",
    Draw4col3btnradio3_B_CALL: "0",
    Draw4col3btnradio3_B_PUT: "0",
  });

  useEffect(() => {
    setLoading(true);
    axios
      .post("/aftermarkrt-check-date", {
        date: selectedDate,
      })
      .then((response) => {
        if (response.data.status === "YES") {
          setDataExist("YES");
        } else {
          setDataExist("NO");
        }
        stSelectAfterIndia(response.data.items);
        setLoading(false);
        // console.log(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [selectedDate]);

  const handleChangeAftermarket = (e) => {
    const { name, value } = e.target;
    stSelectAfterIndia((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // console.log(selectAfterIndia)
  };

  const AfterMarketSubmit = (event) => {
    event.preventDefault();
    const data = {
      ...selectAfterIndia,
      dateFind: selectedDate,
    };
    let dateRaw = dataExist;
    setLoading(true);
    const apiUrl =
      dateRaw !== "NO"
        ? `/aftermarkrt-Edit/${selectedDate}`
        : "/aftermarkrt-create";
    axios({
      method: dateRaw !== "NO" ? "put" : "post",
      url: apiUrl,
      data: data,
    })
      .then((response) => {
        if (response.data.status === "YES") {
          setDataExist("YES");
        } else {
          setDataExist("NO");
        }
        setLoading(false);
        toast.success(response.data.message);
        // console.log(response.data.message);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        toast.error("Wrong Data Entered");
        setLoading(false);
      });
  };

  const AfterIndianEntries = [
    {
      name: "Draw1col1btnradio1",
      name2: "Draw1col1btnradio1_B",
      header:
        '<span style="color: yellow">IF NIFTY OH In Call Side  Hit…? % Last Hit Time:</span>',
      header_B:
        '<span style="color: red">IF NIFTY OH In PUT Side Hit…? % Last Hit Time:</span>',
      type: {
        cardNo: "2",
        radio: "YES",
        percentage: "YES",
        time: "YES",
        chart: "YES",
        dateTime: "NO",
        cardOHLC: "NO",
        cardCallPut: "NO",
      },
    },
    {
      name: "Draw1col2btnradio2",
      name2: "Draw1col2btnradio2_B",
      header:
        '<span style="color: yellow">IF BNF OH In Call Side Hit…? % Last Hit Time:</span>',
      header_B:
        '<span style="color: red">IF BNF OH In PUT Side Hit…? % Last Hit Time:',
      type: {
        cardNo: "2",
        radio: "YES",
        percentage: "YES",
        time: "YES",
        chart: "YES",
        dateTime: "NO",
        cardOHLC: "NO",
        cardCallPut: "NO",
      },
    },
    {
      name: "Draw1col3btnradio3",
      name2: "Draw1col3btnradio3_B",
      header:
        '<span style="color: yellow">IF NIFTY OL In PUT Side Hit…? % Last Hit Time:</span>',
      header_B:
        '<span style="color: red">IF NIFTY OL In Call Side Hit…? % Last Hit Time:</span>',
      type: {
        cardNo: "2",
        radio: "YES",
        percentage: "YES",
        time: "YES",
        chart: "YES",
        dateTime: "NO",
        cardOHLC: "NO",
        cardCallPut: "NO",
      },
    },
    {
      name: "Draw2col1btnradio1",
      name2: "Draw2col1btnradio1_B",
      header:
        '<span style="color: yellow">IF BNF OL In PUT Side Hit…? % Last Hit Time:</span>',
      header_B:
        '<span style="color: red">IF BNF OL In Call Side Hit…? % Last Hit Time:</span>',
      type: {
        cardNo: "2",
        radio: "YES",
        percentage: "YES",
        time: "YES",
        chart: "YES",
        dateTime: "NO",
        cardOHLC: "NO",
        cardCallPut: "NO",
      },
    },
    {
      name: "Draw2col2btnradio2",
      name2: "Draw2col2btnradio2_B",
      header: '<span style="color: yellow">Nifty opening Gap Filled  %</span>',
      header_B:
        '<span style="color: red">Bank Nifty opening Gap Filled % </span>',
      type: {
        cardNo: "2",
        radio: "YES",
        percentage: "YES",
        time: "NO",
        dateTime: "NO",
        cardOHLC: "NO",
        cardCallPut: "NO",
      },
    },
    {
      // name: "Draw2col2btnradio3",name2 : "Draw2col2btnradio3_B",
    },
    {
      name: "Draw3col1btnradio1",
      name2: "Draw3col1btnradio1_B",
      header: '<span style="color: yellow">Is Nifty Future OH Hit</span>',
      header_B: '<span style="color: red">Is  Nifty Future OL Hit</span>',
      type: {
        cardNo: "2",
        radio: "YES",
        percentage: "NO",
        time: "NO",
        dateTime: "YES",
        cardOHLC: "NO",
        cardCallPut: "NO",
      },
    },
    {
      name: "Draw3col2btnradio2",
      name2: "Draw3col2btnradio2_B",
      header: '<span style="color: yellow">Is BNF Future OH Hit</span>',
      header_B: '<span style="color: red">Is  BNF Future OL Hit</span>',
      type: {
        cardNo: "2",
        radio: "YES",
        percentage: "NO",
        time: "NO",
        dateTime: "YES",
        cardOHLC: "NO",
        cardCallPut: "NO",
      },
    },
    {
      name: "Draw3col3btnradio3",
      name2: "Draw3col3btnradio3_B",
      header: '<span style="color: yellow">Nifty Spot OHLC Values</span>',
      header_B: '<span style="color: red">Nifty Future OHLC Values</span>',
      type: {
        cardNo: "2",
        radio: "NO",
        percentage: "NO",
        time: "NO",
        dateTime: "NO",
        cardOHLC: "YES",
        cardCallPut: "NO",
      },
    },
    {
      name: "Draw4col1btnradio1",
      name2: "Draw4col1btnradio1_B",
      header: '<span style="color: yellow">BNF Spot OHLC Values</span>',
      header_B: '<span style="color: red">BNF Future OHLC Values</span>',
      type: {
        cardNo: "2",
        radio: "NO",
        percentage: "NO",
        time: "NO",
        dateTime: "NO",
        cardOHLC: "YES",
        cardCallPut: "NO",
      },
    },
    {
      name: "Draw4col2btnradio2",
      header: '<span style="color: red">INDIA VIX OHLC Values</span>',
      header_B: "",
      type: {
        cardNo: "1",
        radio: "NO",
        percentage: "NO",
        time: "NO",
        dateTime: "NO",
        cardOHLC: "YES",
        cardCallPut: "NO",
      },
    },
    {
      name: "Draw4col3btnradio3",
      header: '<span style="color: yellow">Nifty ATM Premioum</span>',
      header_B: '<span style="color: red">BNF ATM Premium</span>',
      type: {
        cardNo: "2",
        radio: "NO",
        percentage: "NO",
        time: "NO",
        dateTime: "NO",
        cardOHLC: "NO",
        cardCallPut: "YES",
      },
    },
  ];

  return (
    <div className="container-fluid border rounded pt-3">
      <ToastContainer
          position="top-left"
          autoClose={2000}
          closeOnClick={true}
        />
      <h3>After Indian Market Time</h3>
      <form
        onSubmit={AfterMarketSubmit}
        className="border-bottom border-danger p-5"
      >
        <div className="row">
          {AfterIndianEntries.map((entry, index) => (
            <div key={index} className="col-lg-6 col-md-6 col-sm-12 d-flex">
              <AfterIndianMarketCard
                cardContent={{
                  header: entry.header,
                  header_B: entry.header_B,
                  name: entry.name,
                  name2: entry.name2,
                }}
                typeReq={entry.type}
                selectedAfterOptions={selectAfterIndia[entry.name]}
                selectedAfterOptions2={selectAfterIndia[entry.name2]}
                loading={loading}
                selectAfterIndia={selectAfterIndia}
                handleChangeAftermarket={handleChangeAftermarket}
              />
            </div>
          ))}
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <button
            type="submit"
            className="btn btn-success card_color blink"
            // data-toggle="button"
            aria-pressed="false"
          >
            Submit
          </button>
        )}
        
      </form>
    </div>
  );
}

export default AfterIndianMarket;

import React,{useState} from 'react'
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import DataEntry from './DataEntry';
import {Routes,Route,Link} from 'react-router-dom';
import IndianEventCalender from '../calender/IndianEventCalender';
import IndianHolidayCalender from '../calender/IndianHolidayCalender';
import USEventCalender from '../calender/USEventCalender';
import USHolidayCalender from '../calender/USHolidayCalender';
import NiftyHeavyWeightageStockResultCalender from '../calender/NiftyHeavyWeightageStockResultCalender'; 
import BankniftyHeavyWeightageStockResultCalender from '../calender/BankniftyHeavyWeightageStockResultCalender';
import DashboardPage from './DashboardPage';
import FilterData from '../filterData/filterData'
import NcoGraphChart from '../ncoPage/NcoGraphChart';
import ChartGallary from '../charts/ChartGallary';
import MyProfile from '../profile/MyProfile';
 
 

 

function Dashboard() {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleSidebarToggle = () => {
        setIsCollapsed(!isCollapsed);
    };
  return (
    <div className="container-fluid position-relative d-flex p-0">
    <div className="wrapper">
    <aside id="sidebar" className={`js-sidebar ${isCollapsed ? 'collapsed' : ''}`}>

            {/* <!-- Content For Sidebar --> */}
            <Sidebar />
        </aside>
        <div className="main">
             <Navbar handleSidebarToggle={handleSidebarToggle} />
{/* <!-- ----------------main-----open-----------------------             --> */}
        <main className="content px-3 py-2">
           
            
            <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/profile" element={<MyProfile />} />
              <Route path="/dataentry" element={<DataEntry />} />
              <Route path="/filterData" element={<FilterData />} />
              <Route path="/ncograph" element={<NcoGraphChart />} />
              <Route path="/chartGallary" element={<ChartGallary />} />
              <Route
                path="/ind-event-calender"
                element={<IndianEventCalender />}
              />
              <Route
                path="/ind-holiday-calender"
                element={<IndianHolidayCalender />}
              />
              <Route
                path="/us-event-calender"
                element={<USEventCalender />}
              />
              <Route
                path="/us-holiday-calender"
                element={<USHolidayCalender />}
              />
              <Route
                path="/Nifty-Heavy-Weightage-Stock-Result-Calender"
                element={<NiftyHeavyWeightageStockResultCalender />}
              />
              <Route
                path="/Banknifty-Heavy-Weightage-Stock-Result-Calender"
                element={<BankniftyHeavyWeightageStockResultCalender />}
              />
            </Routes>
                
            {/* </div> */}
        </main>
    {/* <!-- --------------------main--close------------------------ --> */}
            {/* <Link to="/" className="theme-toggle">
                <i className="fa-regular fa-moon"></i>
                <i className="fa-regular fa-sun"></i>
            </Link> */}
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row text-muted">
                        <div className="col-6 text-start">
                            <p className="mb-0">
                                <Link to="/" className="text-muted">
                                    <strong>URS-KART</strong>
                                </Link>
                            </p>
                        </div>
                        <div className="col-6 text-end">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <Link to="/" className="text-muted">Contact</Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to="" className="text-muted">About Us</Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to="/" className="text-muted">Terms</Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to="/" className="text-muted">Booking</Link>
                                </li>
                       
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

        <Link to="/" className="btn btn-lg btn-primary btn-lg-square back-to-top " style={{ display: 'none' }}><span className="glyphicon glyphicon-arrow-up">^</span></Link>
    </div>
     
     
</div>
  )
}

export default Dashboard
import React, { useEffect, useState } from "react";
import NiftyAndBnfCard from "./NiftyAndBnfCard";
import axios from "./axios";
import Spinner from "../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NiftyAndBnf({ selectedDate }) {
  const [loading, setLoading] = useState(true);
  const [dataExist, setDataExist] = useState("NO");
  const [selectedNiftyOption, setSelectedNiftyOption] = useState({
    date: null,
    Craw1col1btnradio1: "NO",
    Craw1col2btnradio2: "NO",
    Craw1col3btnradio3: "1",
    Craw1col4btnradio4: "2",
    Craw1col5btnradio5: "3",
    Craw1col6btnradio6: "4",
  });

  useEffect(() => {
    setLoading(true);
    axios
      .post("/niftyAndBnf-check-date", {
        date: selectedDate,
      })
      .then((response) => {
        if (response.data.status === "YES") {
          setDataExist("YES");
        } else {
          setDataExist("NO");
        }
        setSelectedNiftyOption(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [selectedDate]);

  const handleChangeNifty = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedNiftyOption((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const niftySubmit = (event) => {
    event.preventDefault();
    const data = {
      ...selectedNiftyOption,
      dateFind: selectedDate,
    };
    let dateRaw = dataExist;
    setLoading(true);
    const apiUrl =
      dateRaw !== "NO"
        ? `/niftyAndBnf-Edit/${selectedDate}`
        : "/niftyAndBnf-create";
    axios({
      method: dateRaw !== "NO" ? "put" : "post",
      url: apiUrl,
      data: data,
    })
      .then((response) => {
        if (response.data.status === "YES") {
          setDataExist("YES");
        } else {
          setDataExist("NO");
        }
        setLoading(false);
        toast.success(response.data.message);
        // console.log(response.data.message);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        toast.error("Wrong Data Entered");
        setLoading(false);
      });
  };

  const m1Entries = [
    {
      name: "Craw1col1btnradio1",
      header:
        '<h3 style="color: red">NIFTY</h3>Is This is the  last Week of the current  month Expiry:',
      type: { radio: "YES", selectOption: "NO" },
    },
    {
      name: "Craw1col2btnradio2",
      header:
        '<h3 style="color: red">BNF</h3>Is This is the  last Week of the current  month Expiry: <span style="color: red"></span>',
      type: { radio: "YES", selectOption: "NO" },
    },
    {
      name: "Craw1col3btnradio3",
      header:
        '<h3 style="color: red">NIFTY</h3>Total Trading days In this Week  <span style="color: red"></span>',
      type: { radio: "NO", selectOption: "YES" },
    },
    {
      name: "Craw1col4btnradio4",
      header:
        '<h3 style="color: red">BNF</h3>Total Trading days In this Week <span style="color: red"></span>',
      type: { radio: "NO", selectOption: "YES" },
    },
    {
      name: "Craw1col5btnradio5",
      header:
        '<h3 style="color: red">NIFTY</h3>Which Days is Today<span style="color: red"></span>',
      type: { radio: "NO", selectOption: "YES" },
    },
    {
      name: "Craw1col6btnradio6",
      header:
        '<h3 style="color: red">BNF</h3>Which Days is Today<span style="color: red"></span>',
      type: { radio: "NO", selectOption: "YES" },
    },
  ];
  return (
    <div className="container-fluid border rounded pt-3">
       <ToastContainer
          position="top-left"
          autoClose={2000}
          closeOnClick={true}
        />
      <h3>NIFTY & BNF</h3>

      <form onSubmit={niftySubmit} className="border-bottom border-danger p-3">
        <div className="row">
          {m1Entries.map((entry, index) => (
            <div key={index} className="col-xs-6 col-sm-6 d-flex">
              <NiftyAndBnfCard
                cardContent={{
                  header: entry.header,
                  name: entry.name,
                }}
                handleChangeNifty={handleChangeNifty}
                selectedNiftyOption={selectedNiftyOption[entry.name]}
                selectedNiftyOption2={selectedNiftyOption}
                typeReq={entry.type}
                loading={loading}
              />
            </div>
          ))}
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <button
            type="submit"
            className="btn btn-success card_color blink"
            // data-toggle="button"
            aria-pressed="false"
          >
            Submit
          </button>
        )}
       
      </form>
    </div>
  );
}

export default NiftyAndBnf;

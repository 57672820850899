import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import axios from "../pages/axios";
import Spinner from "../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid } from "@mui/x-data-grid";

function BankniftyHeavyWeightageStockResultCalenderStock({onStockAdded}) {
    const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [rowsTable, setrowsTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addStockSuccess, setAddStockSuccess] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    onStockAdded();
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get("/banknifty-heavy-read-stocks")
      .then((response) => {
        setrowsTable(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [addStockSuccess]);

  const handleAddButtonClick = (e) => {
    e.preventDefault();
    setAddStockSuccess(false);
    axios
      .post("/banknifty-heavy-add-stock", { value: inputValue })
      .then((response) => {
        setInputValue("");
        if (response.data.status === true) {
          toast.success("Stock Added successfully");
        } else {
          toast.error("Wrong Data, Error");
        }
        setAddStockSuccess(true);
      })
      .catch((error) => {
        console.error("Error adding value:", error);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 30 },
    { field: "stocks", headerName: "Stocks", width: 350 },
    { field: "created", headerName: "created", width: 160 },
  ];
  return (
    <div className="d-flex justify-content-center align-items-center">
    <ToastContainer />
    <button
      className="form-control"
      onClick={openModal}
      style={{ width: "50%" }}
    >
      Add New Stock
    </button>

    <Modal isOpen={isOpen} onClose={closeModal} ariaHideApp={false}>
      <form onSubmit={handleAddButtonClick}>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">Add Stocks</span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Enter text"
            aria-label="Enter text"
            aria-describedby="basic-addon2"
            name="Bankinputstocksnifty"
            value={inputValue}
            onChange={handleInputChange}
            required
          />
          <div className="input-group-append">
            <button className="btn   btn-success" type="submit">
              Add
            </button>
          </div>
        </div>
      </form>
      <div
        className="row"
        style={{ height: 400, backgroundColor: "#f2f2f2" }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <DataGrid
            rows={rowsTable}
            columns={columns}
            // pageSize={5}
            // rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
          />
        )}
      </div>
      <button
        className="btn btn-warning  modal-close-button"
        onClick={closeModal}
      >
        Close
      </button>
    </Modal>
  </div>
  )
}

export default BankniftyHeavyWeightageStockResultCalenderStock